import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { autocrossActions } from '../redux/autocross';

import { Column, Row, CircleLinkButton } from '../lib/styles';

import { Version } from '../components/version';
import { DeleteButton } from '../components/delete-button';

const Wrapper = styled.div`
  height: 100vh;
  padding: 20px;
  overflow-x: hidden;
`;

const BackButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

class Data extends React.Component {

  reset = () => {
    this.props.resetData();
    this.props.history.push(`/`);
  };

  render() {
    return (
      <Wrapper>
        <Version />

        <Column justifyContent="center">
          <Row justifyContent="center">
          </Row>
        </Column>

        <BackButtonWrapper>
          <CircleLinkButton to="/">
            Back
          </CircleLinkButton>
        </BackButtonWrapper>

        <ButtonWrapper>
          <DeleteButton onDelete={this.reset}>
            Reset
          </DeleteButton>
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    ...state.autocrossState
  };
};

const mapDispatchToProps = {
  ...autocrossActions
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Data));

