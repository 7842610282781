
export const locationUtils = {
  convertToRadians,
  calculateDistance,
  convertMetersToMiles,
  convertMilesToMeters,
  convertMetersPerSecondToMilesPerHour,
  convertMilesPerHourToMetersPerSecond
};

function convertToRadians(value) {
  return value * Math.PI / 180;
}

function calculateDistance(latitude1, longitude1, latitude2, longitude2) {
  // R is the radius of the earth in kilometers
  const R = 6371;

  const deltaLatitude = locationUtils.convertToRadians(latitude2 - latitude1);
  const deltaLongitude = locationUtils.convertToRadians(longitude2 - longitude1);
  const latitude1Radians = locationUtils.convertToRadians(latitude1);
  const latitude2Radians = locationUtils.convertToRadians(latitude2);

  const a = Math.sin(deltaLatitude/2) *
    Math.sin(deltaLatitude/2) +
    Math.cos(latitude1Radians) *
    Math.cos(latitude2Radians) *
    Math.sin(deltaLongitude/2) *
    Math.sin(deltaLongitude/2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c * 1000;
  return d;
}

function convertMetersToMiles(meters) {
  return meters * 0.000621371;
}

function convertMilesToMeters(miles) {
  return miles * 1609.344;
}

function convertMetersPerSecondToMilesPerHour(metersPerSecond) {
  return metersPerSecond * 2.23694;
}

function convertMilesPerHourToMetersPerSecond(milesPerHour) {
  return milesPerHour / 2.23694;
}

