import React from 'react';

import { CircleButton } from '../lib/styles';

export class DeleteButton extends React.Component {

  constructor(props) {
    super(props);

    this.clicksRequired = 5;

    this.state = {
      clicksRemaining: this.clicksRequired
    };
  }

  onClick = () => {
    if (this.props.onDelete && this.state.clicksRemaining <= 1) {
      this.setState({
        clicksRemaining: this.clicksRequired
      });
      this.props.onDelete();
    } else {
      this.setState({
        clicksRemaining: this.state.clicksRemaining - 1
      });
    }
  }

  render() {
    const { deleteText, styles, disabled } = this.props;

    return (
        <CircleButton type="button" onClick={this.onClick} styles={styles} disabled={disabled}>
          <span className="ti-trash" />
          {this.state.clicksRemaining === this.clicksRequired ? (
            this.props.children
          ) : (
            <span>{deleteText || 'Yes?'}({this.state.clicksRemaining})</span>
          )}
        </CircleButton>
    );
  }
}

