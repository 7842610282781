import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { autocrossActions } from '../redux/autocross';

import { LOCATION_MIN_ACCURACY } from '../lib/constants';
import { Column, Row, CircleButton, CircleLinkButton } from '../lib/styles';

import { Wakelock } from '../components/wakelock';
import { LoadingSpinner } from '../components/loading-spinner';

const Wrapper = styled.div`
  height: 100vh;
  padding: 20px;
  overflow-x: hidden;
`;

const Title = styled.h1`
  color: white;
`;

const TimingWrapper = styled.div`
  display: flex;
  flex-direction: column; //${({ isLandscape }) => isLandscape ? 'row' : 'column'};
  align-items: center;
  width: 100%;
`;

const TimingBar = styled.div`
  display: flex;
  width: 80px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 4px;
  height: 34vh;
  overflow: hidden;
`;

const TimingBarSlow = styled.div`
  ${({ timeDelta }) => timeDelta < 0 ? 'display: none;' : ''}
  align-self: flex-end;
  background-color: red;
  width: 100%;
  height: ${({ timeDelta }) => Math.min(1500, Math.abs(timeDelta)) / 1500 * 100}%;
`;

const TimingBarFast = styled.div`
  ${({ timeDelta }) => timeDelta > 0 ? 'display: none;' : ''}
  background-color: green;
  width: 100%;
  height: ${({ timeDelta }) => Math.min(1500, Math.abs(timeDelta)) / 1500 * 100}%;
`;

const TimeDelta = styled.h1`
  color: white;
`;

const BackButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

class Run extends React.Component {

  constructor(props) {
    super(props);

    this.geolocationWatchId = null;
  }

  componentDidMount() {
    // reset stuff
    this.releaseLocation();

    // start stuff
    this.startLocationUpdates();

    // add a short delay because the first few location updates can be unreliable
    // which messes up the launch
    this.props.setLoading(true);
    window.setTimeout(() => {
      this.props.setLoading(false);
    }, 3000);
  }

  componentWillUnmount() {
    this.releaseLocation();
    this.props.setLoading(false);
  }

  /* location */

  startLocationUpdates = () => {
    if (navigator.geolocation) {
      const options = {
        enableHighAccuracy: true,
        maximumAge: 0
      };
      this.geolocationWatchId = navigator.geolocation.watchPosition(this.onLocationUpdated, this.onLocationError, options);
    }
  };

  releaseLocation = () => {
    if (this.geolocationWatchId) {
      navigator.geolocation.clearWatch(this.geolocationWatchId);
      this.geolocationWatchId = null;
    }
  };

  onLocationUpdated = (location) => {
    const { latitude, longitude, speed, isLoading } = this.props;

    // ignore inaccurate locations
    if (location.coords.accuracy > LOCATION_MIN_ACCURACY) {
      return;
    }

    if (!isLoading) {
      this.props.updateLocation(location);
      this.props.updateSpeed(location);
      this.props.updateAccuracy(location);
      this.props.processLocationUpdate(location, latitude, longitude, speed);
    }
  }

  onLocationError = (error) => {
    // shrug
    console.log('Geolocation error:', error);
  }

  /* button actions */

  stop = () => {
    this.props.computeIdealRun();
    this.props.history.push(`/`);
  };

  restart = () => {
    this.props.setOdometer(0);
    this.props.setTimeDelta(0);
    this.props.setLaunchedAt(null);
    this.props.setLoading(false);
    this.props.setCurrentRun([]);
    this.props.setIdealRunIndex(0);
  };

  render() {
    const { launchedAt, idealRun, timeDelta, isLoading } = this.props;

    const isLandscape = window.innerWidth > window.innerHeight;

    return (
      <Wrapper>
        <Wakelock />

        <Column justifyContent="center">
          <Row justifyContent="center">
            {isLoading && (
              <LoadingSpinner />
            )}

            {(!isLoading && !launchedAt) && (
              <Title>Waiting for launch...</Title>
            )}

            {(!isLoading && !!launchedAt && !idealRun.length) && (
              <Title>Collecting first run...</Title>
            )}

            {(!isLoading && !!launchedAt && !!idealRun.length) && (
              <TimingWrapper isLandscape={isLandscape}>
                <TimingBar>
                  <TimingBarSlow timeDelta={timeDelta}>
                  </TimingBarSlow>
                </TimingBar>
                <TimeDelta>
                  {timeDelta < 0 ? '-' : '+'}
                  {Math.abs(Math.min(timeDelta, 9999) / 1000).toFixed(3)}
                </TimeDelta>
                <TimingBar>
                  <TimingBarFast timeDelta={timeDelta}>
                  </TimingBarFast>
                </TimingBar>
              </TimingWrapper>
            )}
          </Row>
        </Column>

        <BackButtonWrapper>
          {!launchedAt ? (
            <CircleLinkButton to="/">
              Back
            </CircleLinkButton>
          ) : (
            <CircleButton onClick={this.stop}>
              Finish
            </CircleButton>
          )}
        </BackButtonWrapper>

        {(!isLoading && !!launchedAt) && (
          <ButtonWrapper>
            <CircleButton onClick={this.restart} styles="font-size: 1.25rem;">
              Restart
            </CircleButton>
          </ButtonWrapper>
        )}
      </Wrapper>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    ...state.autocrossState
  };
};

const mapDispatchToProps = {
  ...autocrossActions
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Run));

