import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Main from './pages/Main';
import Run from './pages/Run';
import Data from './pages/Data';

class App extends React.Component {

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/send-it">
            <Run />
          </Route>
          <Route path="/data">
            <Data />
          </Route>
          <Route path="/">
            <Main />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
