
import {
  STORAGE_KEY_IDEAL_RUN,
  STORAGE_KEY_RUNS
} from '../../lib/constants';

import { storageUtils } from '../../lib/storage-utils';

export const autocrossInitialState = {
  latitude: null,
  longitude: null,
  speed: 0, // m/s
  accuracy: 0, // meters
  odometer: 0, // meters
  timeDelta: 0, // milliseconds -- this is the driver's score, the time difference between the current run and the ideal run
  launchedAt: null, // date
  isLoading: false, // waiting for the GPS to settle or the car to stop before launching
  currentRun: [],
  idealRunIndex: 0,
  idealRun: storageUtils.getItem(STORAGE_KEY_IDEAL_RUN) || [],
  runs: storageUtils.getItem(STORAGE_KEY_RUNS) || []
};

export const autocrossActions = {
  updateLocation: (location) => ({ type: 'UPDATE_LOCATION_SAGA', location }),
  updateSpeed: (location) => ({ type: 'UPDATE_SPEED_SAGA', location }),
  updateAccuracy: (location) => ({ type: 'UPDATE_ACCURACY_SAGA', location }),

  processLocationUpdate: (location, prevLatitude, prevLongitude, prevSpeed) => ({ type: 'PROCESS_LOCATION_SAGA', location, prevLatitude, prevLongitude, prevSpeed }),
  updateCurrentRun: (dataPoint) => ({ type: 'UPDATE_CURRENT_RUN_SAGA', dataPoint }),
  computeTimeDelta: (dataPoint) => ({ type: 'COMPUTE_TIME_DELTA_SAGA', dataPoint }),
  computeIdealRun: () => ({ type: 'COMPUTE_IDEAL_RUN_SAGA' }),
  addRun: (run) => ({ type: 'ADD_RUN_SAGA', run }),
  resetData: () => ({ type: 'RESET_DATA_SAGA' }),

  playCatNoise: () => ({ type: 'PLAY_CAT_NOISE_SAGA' }),

  setLatitude: (latitude) => ({ type: 'RALLY_STATE', latitude }),
  setLongitude: (longitude) => ({ type: 'RALLY_STATE', longitude }),
  setSpeed: (speed) => ({ type: 'RALLY_STATE', speed }),
  setAccuracy: (accuracy) => ({ type: 'RALLY_STATE', accuracy }),
  setOdometer: (odometer) => ({ type: 'RALLY_STATE', odometer }),
  setTimeDelta: (timeDelta) => ({ type: 'RALLY_STATE', timeDelta }),
  setLaunchedAt: (launchedAt) => ({ type: 'RALLY_STATE', launchedAt }),
  setLoading: (isLoading) => ({ type: 'RALLY_STATE', isLoading }),
  setRuns: (runs) => ({ type: 'RALLY_STATE', runs }),
  setCurrentRun: (currentRun) => ({ type: 'RALLY_STATE', currentRun }),
  setIdealRunIndex: (idealRunIndex) => ({ type: 'RALLY_STATE', idealRunIndex }),
  setIdealRun: (idealRun) => ({ type: 'RALLY_STATE', idealRun })
};

export function autocrossReducer(state = autocrossInitialState, action) {

  switch (action.type) {
    case 'RALLY_STATE':
      const newState = {
        ...state,
        ...action
      };
      delete newState.type;
      return newState;

    case 'CLEAR_STATE':
      return autocrossInitialState;

    default:
      return state;
  }
}

