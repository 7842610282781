import { Link } from 'react-router-dom';
import styled from 'styled-components';
import MaskedInput from 'react-text-mask';

// layout

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.align || 'center'};
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  height: 100%;
  width: 100%;
  ${(props) => props.styles}
`;

export const Row = styled.div`
  display: flex;
  align-items: ${(props) => props.align || 'center'};
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  width: 100%;
  ${(props) => props.styles}
`;

// buttons

export const buttonStyle = `
  display: inline-block;
  width: 100%;
  background: none;
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  font-size: 18px;
  padding: 12px 20px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  font-weight: 400;
  white-space: nowrap;

  &:hover:not(:disabled) {
    color: black;
    background-color: white;
  }

  &:disabled {
    cursor: default;
    color: rgba(255, 255, 255, .5);
    border-color: rgba(255, 255, 255, .5);
  }
`;

export const circleButtonStyle = `
  width: 5rem;
  height: 5rem;
  border-radius: 5rem;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 5rem;
  vertical-align: middle;
  padding: 0;
  text-transform: capitalize;
  letter-spacing: 0.075rem;
`;

export const Button = styled.button`
  ${buttonStyle}
  ${(props) => props.styles}
`;

export const LinkButton = styled(Link)`
  ${buttonStyle}
  ${(props) => props.styles}
`;

export const SmallButton = styled.button`
  ${buttonStyle}
  font-size: 1rem;
  padding: 8px 12px;
  width: auto;
`;

export const CircleButton = styled.button`
  ${buttonStyle}
  ${circleButtonStyle}
  ${(props) => props.styles}
`;

export const CircleButtonLarge = styled.button`
  ${buttonStyle}
  ${circleButtonStyle}
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 12.5rem;
  font-size: 2.5rem;
  line-height: 12.5rem;
  ${(props) => props.styles}
`;

export const CircleLinkButton = styled(Link)`
  ${buttonStyle}
  ${circleButtonStyle}
  ${(props) => props.styles}
`;

export const CircleLinkButtonLarge = styled(Link)`
  ${buttonStyle}
  ${circleButtonStyle}
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 12.5rem;
  font-size: 2.5rem;
  line-height: 12.5rem;
  ${(props) => props.styles}
`;

// forms

export const inputStyle = `
  background: none;
  border-radius: 4px;
  width: 100%;
  height: 37px;
  border: 1px solid white;
  font-size: 1rem;
  text-align: right;
  padding-right: 8px;
  color: white;

  &:focus,
  &:active {
    background: none;
  }
`;

export const InputWrapper = styled.div`
  height: 100%;
  position: relative;
  margin-right: 4px;
  width: 100%;
  ${(props) => props.styles}
`;

export const Input = styled.input`
  ${inputStyle}
  ${(props) => props.styles}
`;

export const InputMasked = styled(MaskedInput)`
  ${inputStyle}
  ${(props) => props.styles}
`;

export const Select = styled.select`
  ${inputStyle}
  ${(props) => props.styles}
`;

export const inputLabelStyle = `
  top: 9px;
  left: 8px;
  position: absolute;
  color: white;
`;

export const InputLabel = styled.label`
  ${inputLabelStyle}
  ${(props) => props.styles}
`;

export const RadioWrapper = styled.div`
  height: 100%;
  position: relative;
  margin-right: 4px;
  ${(props) => props.styles}
`;

export const Radio = styled.input`
  display: none;
  ${(props) => props.styles}

  &:checked + label {
    background-color: white;
    color: black;
  }
`;

export const RadioLabel = styled.label`
  ${buttonStyle}
  color: white;
  font-size: 1rem;
  padding: 8px 12px;
  ${(props) => props.styles}
`;

