import { put, select, takeLeading } from 'redux-saga/effects';
import _ from 'lodash';

import {
  STORAGE_KEY_IDEAL_RUN,
  STORAGE_KEY_RUNS
} from '../../lib/constants';

import { locationUtils } from '../../lib/location-utils';
import { storageUtils } from '../../lib/storage-utils';
import { audioUtils } from '../../lib/audio-utils';

import { autocrossActions } from './autocross-reducer';

export function * updateLocationSaga(action) {
  const { location } = action;
  yield put(autocrossActions.setLatitude(location.coords.latitude));
  yield put(autocrossActions.setLongitude(location.coords.longitude));
}

export function * updateSpeedSaga(action) {
  const { location } = action;
  yield put(autocrossActions.setSpeed(location.coords.speed));
}

export function * updateAccuracySaga(action) {
  const { location } = action;
  yield put(autocrossActions.setAccuracy(location.coords.accuracy));
}

export function * processLocationUpdateSaga(action) {
  const { location, prevLatitude, prevLongitude, prevSpeed } = action;

  if (!prevLatitude || !prevLongitude) {
    return;
  }

  // get state
  const { odometer, launchedAt, idealRun, runs } = yield select((state) => state.autocrossState);
  const now = new Date();
  const runNumber = runs.length + 1; // +1 because `runs` doesn't include the current run yet

  // speed
  const speed = location.coords.speed;

  // accuracy
  const accuracy = location.coords.accuracy;

  // detect launch
  if (!launchedAt) {
    if (speed > 5) { // 5m/s ~ 12mph, 1m/s ~ 2mph
      // car just launched

      // set the launch as the (0, 0) data point
      const dataPointZero = {
        odometer: 0,
        elapsedTime: 0,
        speed,
        accuracy,
        runNumber
      };

      yield put(autocrossActions.setOdometer(0));
      yield put(autocrossActions.setLaunchedAt(now));
      yield put(autocrossActions.setLoading(false));
      yield put(autocrossActions.setTimeDelta(0));
      yield put(autocrossActions.setIdealRunIndex(0));
      yield put(autocrossActions.setCurrentRun([]));
      yield put(autocrossActions.updateCurrentRun(dataPointZero));
    }

    // exit whether the car hasn't launched yet or if it just launched
    // * nothing to be done if the car hasn't launched yet
    // * otherwise treat the launch as (0, 0) and calculate differences with subsequent updates
    return;
  }

  // odometer
  const currentLatitude = location.coords.latitude;
  const currentLongitude = location.coords.longitude;
  const distance = locationUtils.calculateDistance(currentLatitude, currentLongitude, prevLatitude, prevLongitude);
  const newOdometer = (odometer + distance);

  // time
  const elapsedTime = (now - launchedAt); // milliseconds

  const dataPoint = {
    odometer: newOdometer,
    elapsedTime,
    speed,
    accuracy,
    runNumber
  };

  yield put(autocrossActions.setOdometer(newOdometer));
  yield put(autocrossActions.updateCurrentRun(dataPoint));
  yield put(autocrossActions.computeTimeDelta(dataPoint, idealRun));
}

export function * updateCurrentRunSaga(action) {
  const { currentRun } = yield select((state) => state.autocrossState);
  const { dataPoint } = action;
  const result = currentRun.slice(); // see https://redux.js.org/usage/structuring-reducers/immutable-update-patterns#inserting-and-removing-items-in-arrays
  result.push(dataPoint);
  yield put(autocrossActions.setCurrentRun(result));
}

export function * computeTimeDeltaSaga(action) {
  const { idealRun, idealRunIndex } = yield select((state) => state.autocrossState);
  const { dataPoint } = action;

  if (!idealRun.length) {
    return;
  }

  // find the data point in the ideal run where the current data point odometer is between ideal data point odometers
  // then take a linear function y=mx+b via t[ideal] = m(currentOdometer - idealOdometer[0]) + idealTime[0]
  // and get d(t) = t[current] - t[ideal]

  let index = _.findIndex(idealRun, (x) => x.odometer > dataPoint.odometer, idealRunIndex) - 1;
  let point0 = idealRun[index];
  let point1 = idealRun[index + 1];

  // if the current run has passed the ideal run for number of data points, compare against the slope from the last two data points in the ideal run
  // which probably doesn't matter that much -- the driver should have completed their lap and is probably heading back to grid or something
  if (index < 0) {
    point0 = _.nth(idealRun, -2);
    point1 = _.nth(idealRun, -1);
  }

  // creating a graph like this:
  //  |
  // t|    . <---- point1
  // i| . / <---- dataPoint -- how far away is it from the line?
  // m| |/
  // e| /
  //  ./ <---- point0
  //  |
  //0 ------------------
  //  0    odometer
  //
  // to remove the need to find the y intercept, the point0 and point1 data points get translated to X=0
  // then the intercept is just the time at point0
  const m = (point1.elapsedTime - point0.elapsedTime) / (point1.odometer - point0.odometer);
  const x = dataPoint.odometer - point0.odometer; // normalize the current odometer reading
  const b = point0.elapsedTime;

  const idealTime = (m * x) + b;
  const result = dataPoint.elapsedTime - idealTime;

  yield put(autocrossActions.setTimeDelta(result));
  yield put(autocrossActions.setIdealRunIndex(index));
}

export function * computeIdealRunSaga(action) {
  const { currentRun, idealRun } = yield select((state) => state.autocrossState);

  // 1. if idealRun not set, just take the first run
  // 2. compare the latest run to the ideal run and splice segments by best split duration
  //    (compute split times from 100m intervals -- group by Math.floor(odometer / 100))
  //
  // even a lap that was slow overall might have the best time for one of the split sections!

  if (!idealRun.length) {
    yield put(autocrossActions.setIdealRun(currentRun));
    yield put(autocrossActions.addRun(currentRun));
    yield put(autocrossActions.setCurrentRun([]));
    yield put(autocrossActions.setLaunchedAt(null));
    storageUtils.setItem(STORAGE_KEY_IDEAL_RUN, currentRun);
    return;
  }

  let result = [];

  const idealRunSegments = _.values(_.groupBy(idealRun, (dataPoint) => Math.floor(dataPoint.odometer / 100)));
  const currentRunSegments = _.values(_.groupBy(currentRun, (dataPoint) => Math.floor(dataPoint.odometer / 100)));

  const totalSegments = Math.max(idealRunSegments.length, currentRunSegments.length);
  for (let i = 0; i < totalSegments; i++) {
    const idealRunSegment = idealRunSegments[i];
    const currentRunSegment = currentRunSegments[i];

    // if the latest run contains more chunks than the ideal run
    if (!idealRunSegment) {
      result.concat(currentRunSegment);
      continue;
    }

    // if the ideal run contains more chunks than the latest run
    if (!currentRunSegment) {
      result.concat(idealRunSegment);
      continue;
    }

    // otherwise, compare segment times and take the fastest
    const idealSplitDuration = _.last(idealRunSegment).elapsedTime - _.first(idealRunSegment).elapsedTime;
    const latestSplitDuration = _.last(currentRunSegment).elapsedTime - _.first(currentRunSegment).elapsedTime;

    // take the latest if it was faster
    if (latestSplitDuration < idealSplitDuration) {
      result = result.concat(currentRunSegment);
    } else {
      result = result.concat(idealRunSegment);
    }
  }

  yield put(autocrossActions.setIdealRun(result));
  yield put(autocrossActions.addRun(currentRun));
  yield put(autocrossActions.setCurrentRun([]));
  yield put(autocrossActions.setLaunchedAt(null));
  storageUtils.setItem(STORAGE_KEY_IDEAL_RUN, result);
}

export function * addRunSaga(action) {
  const { runs } = yield select((state) => state.autocrossState);
  const { run } = action;
  const result = runs.slice(); // see https://redux.js.org/usage/structuring-reducers/immutable-update-patterns#inserting-and-removing-items-in-arrays
  result.push(run);
  yield put(autocrossActions.setRuns(result));
  storageUtils.setItem(STORAGE_KEY_RUNS, result);
}

export function * resetDataSaga(action) {
  yield put(autocrossActions.setOdometer(0));
  yield put(autocrossActions.setTimeDelta(0));
  yield put(autocrossActions.setLaunchedAt(null));
  yield put(autocrossActions.setLoading(false));
  yield put(autocrossActions.setCurrentRun([]));
  yield put(autocrossActions.setIdealRunIndex(0));
  yield put(autocrossActions.setIdealRun([]));
  yield put(autocrossActions.setRuns([]));
  storageUtils.setItem(STORAGE_KEY_IDEAL_RUN, []);
  storageUtils.setItem(STORAGE_KEY_RUNS, []);
}

export function playCatNoiseSaga(action) {
  const number = _.random(1, 27);
  const path = `/sounds/cat${number}.mp3`;
  audioUtils.playSound(path);
}

export const autocrossSagas = [
  takeLeading('UPDATE_LOCATION_SAGA', updateLocationSaga),
  takeLeading('UPDATE_SPEED_SAGA', updateSpeedSaga),
  takeLeading('UPDATE_ACCURACY_SAGA', updateAccuracySaga),
  takeLeading('PROCESS_LOCATION_SAGA', processLocationUpdateSaga),
  takeLeading('UPDATE_CURRENT_RUN_SAGA', updateCurrentRunSaga),
  takeLeading('COMPUTE_TIME_DELTA_SAGA', computeTimeDeltaSaga),
  takeLeading('COMPUTE_IDEAL_RUN_SAGA', computeIdealRunSaga),
  takeLeading('ADD_RUN_SAGA', addRunSaga),
  takeLeading('RESET_DATA_SAGA', resetDataSaga),
  takeLeading('PLAY_CAT_NOISE_SAGA', playCatNoiseSaga)
];

