import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { autocrossActions } from '../redux/autocross';

import { Column, Row, CircleButton, CircleLinkButton, CircleLinkButtonLarge } from '../lib/styles';

import { Version } from '../components/version';
import { Wakelock } from '../components/wakelock';

const Wrapper = styled.div`
  height: 100vh;
  padding: 20px;
  overflow-x: hidden;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

class Main extends React.Component {

  render() {
    const { runs } = this.props;

    return (
      <Wrapper>
        <Wakelock />
        <Version />

        <Column justifyContent="center">
          <Row justifyContent="center">
            <CircleLinkButtonLarge to="/send-it">
              Start
            </CircleLinkButtonLarge>
          </Row>
        </Column>

        <ButtonWrapper>
          {runs.length ? (
            <CircleLinkButton to="/data" styles="font-size: 1.25rem;">
              {runs.length} run{runs.length === 1 ? '' : 's'}
            </CircleLinkButton>
          ) : (
            <CircleButton styles="font-size: 1.25rem;" disabled>
              0 runs
            </CircleButton>
          )}
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    ...state.autocrossState
  };
};

const mapDispatchToProps = {
  ...autocrossActions
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);

