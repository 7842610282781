
// location

export const LOCATION_MIN_ACCURACY = 30;

// storage keys

export const STORAGE_KEY_IDEAL_RUN = 'idealRun';

export const STORAGE_KEY_RUNS = 'runs';

