import { all } from 'redux-saga/effects';

import { autocrossSagas } from './autocross/autocross-sagas';

export function * rootSaga () {
  yield all([
    ...autocrossSagas
  ]);
}

