import { combineReducers } from 'redux';

import { autocrossReducer, autocrossInitialState } from './autocross/autocross-reducer';

export const initialState = {
  autocrossState: autocrossInitialState
};

export const rootReducer = () => combineReducers({
  autocrossState: autocrossReducer
});

